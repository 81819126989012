import "/app/vendor/bundle/ruby/2.7.0/gems/thredded-1.1.0/app/assets/javascripts/thredded/core/thredded.es6";
import "/app/vendor/bundle/ruby/2.7.0/gems/thredded-1.1.0/app/assets/javascripts/thredded/core/on_page_load.es6";
import "/app/vendor/bundle/ruby/2.7.0/gems/thredded-1.1.0/app/assets/javascripts/thredded/components/spoilers.es6";
import "/app/vendor/bundle/ruby/2.7.0/gems/thredded-1.1.0/app/assets/javascripts/thredded/core/serialize_form.es6";
import "/app/vendor/bundle/ruby/2.7.0/gems/thredded-1.1.0/app/assets/javascripts/thredded/core/debounce.es6";
import "/app/vendor/bundle/ruby/2.7.0/gems/thredded-1.1.0/app/assets/javascripts/thredded/components/preview_area.es6";
import "/app/vendor/bundle/ruby/2.7.0/gems/thredded-1.1.0/vendor/assets/javascripts/textcomplete.min.js";
import "/app/vendor/bundle/ruby/2.7.0/gems/thredded-1.1.0/app/assets/javascripts/thredded/dependencies/textcomplete.js";
import "/app/vendor/bundle/ruby/2.7.0/gems/thredded-1.1.0/app/assets/javascripts/thredded/core/escape_html.es6";
import "/app/vendor/bundle/ruby/2.7.0/gems/thredded-1.1.0/app/assets/javascripts/thredded/components/user_textcomplete.es6";
import "/app/vendor/bundle/ruby/2.7.0/gems/thredded-1.1.0/app/assets/javascripts/thredded/components/mention_autocompletion.es6";
import "/app/vendor/bundle/ruby/2.7.0/gems/thredded-1.1.0/vendor/assets/javascripts/autosize.js";
import "/app/vendor/bundle/ruby/2.7.0/gems/thredded-1.1.0/app/assets/javascripts/thredded/dependencies/autosize.js";
import "/app/vendor/bundle/ruby/2.7.0/gems/thredded-1.1.0/app/assets/javascripts/thredded/components/post_form.es6";
import "/app/vendor/bundle/ruby/2.7.0/gems/thredded-1.1.0/app/assets/javascripts/thredded/components/users_select.es6";
import "/app/vendor/bundle/ruby/2.7.0/gems/thredded-1.1.0/app/assets/javascripts/thredded/components/quote_post.es6";
import "/app/vendor/bundle/ruby/2.7.0/gems/thredded-1.1.0/app/assets/javascripts/thredded/components/submit_hotkey.es6";
import "/app/vendor/bundle/ruby/2.7.0/gems/thredded-1.1.0/app/assets/javascripts/thredded/components/turboforms.es6";
import "/app/vendor/bundle/ruby/2.7.0/gems/thredded-1.1.0/app/assets/javascripts/thredded/components/time_stamps.es6";
import "/app/vendor/bundle/ruby/2.7.0/gems/thredded-1.1.0/app/assets/javascripts/thredded/components/currently_online.es6";
import "/app/vendor/bundle/ruby/2.7.0/gems/thredded-1.1.0/app/assets/javascripts/thredded/components/topic_form.es6";
import "/app/vendor/bundle/ruby/2.7.0/gems/thredded-1.1.0/app/assets/javascripts/thredded/components/flash_messages.es6";
import "/app/vendor/bundle/ruby/2.7.0/gems/thredded-1.1.0/app/assets/javascripts/thredded/components/user_preferences_form.es6";
import "/app/vendor/bundle/ruby/2.7.0/gems/thredded-1.1.0/app/assets/javascripts/thredded/components/topics.es6";
import "/app/vendor/bundle/ruby/2.7.0/gems/thredded-1.1.0/app/assets/javascripts/thredded/core/hide_soft_keyboard.es6";
import "/app/vendor/bundle/ruby/2.7.0/gems/thredded-1.1.0/app/assets/javascripts/thredded/core/csrf_tokens.es6";
import "/app/vendor/bundle/ruby/2.7.0/gems/thredded-1.1.0/app/assets/javascripts/thredded/thredded.es6";
import "@rails/ujs";
import "/app/vendor/bundle/ruby/2.7.0/gems/thredded-1.1.0/app/assets/javascripts/thredded/dependencies/ujs.js";
import "/app/vendor/bundle/ruby/2.7.0/gems/timeago_js-3.0.2.2/assets/javascripts/timeago.js";
import "/app/vendor/bundle/ruby/2.7.0/gems/thredded-1.1.0/app/assets/javascripts/thredded/dependencies/timeago.js";
import "/app/vendor/bundle/ruby/2.7.0/gems/thredded-1.1.0/app/assets/javascripts/thredded/dependencies.js";
import "/app/vendor/bundle/ruby/2.7.0/gems/thredded-1.1.0/app/assets/javascripts/thredded.es6";
